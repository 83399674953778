import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

import "../../css/modal.css";
import "../../css/font-awesome.css";
import "../../css/bootstrap.css";

const Subscribe = () => (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
            <div>
                {modal ? (
                    <Link to={closeTo}>
                        <div style={{ width: "100%", display: "block" }}>
                            <i
                                className="fa fa-times"
                                style={{ float: "right", top: "0", color: "#20ABB5" }}
                            ></i>
                        </div>
                        <br />
                    </Link>
                ) : (
                        <header>
                            <h1 style={{ color: "rgba(0,0,0,0.87)" }}>Subscribe</h1>
                        </header>
                    )}

                <header>
                    <h3>Subscribe</h3>
                </header>
                <form action="https://formspree.io/mvvvzzvm" method="POST">
                    {/* <div class="form-group">
            <label for="exampleFormControlInput1">Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="John Smith"
              required
            />
          </div> */}
                    <div class="form-group">
                        {/* <label for="exampleFormControlInput2">Email</label> */}
                        <input
                            type="email"
                            class="form-control"
                            id="exampleFormControlInput2"
                            placeholder="name@example.com"
                            name="_replyto"
                            required
                        />
                    </div>
                    {/* <label>
            Name: <input type="text" name="name" />
          </label>
          <label>
            Email: <input type="email" name="_replyto" />
          </label> */}
                    {/* <input type="submit" value="Send" /> */}
                    <button type="submit" class="btn btn-primary subscribeButt" style={{ float: "right" }}>
                        Submit
          </button>
                    <input type="hidden" name="_next" value="/" />
                </form>

                {/* <Link to="/">Go back to the homepage</Link> */}
            </div>
        )}
    </ModalRoutingContext.Consumer>
)

export default Subscribe